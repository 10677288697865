import { getEmailFromToken } from '../auth';

export const EventType = {
  PRICING_EARLY_ADOPTERS: 'PRICING_EARLY_ADOPTERS',
  PRICING_ENTERPRISE: 'PRICING_ENTERPRISE',

  DELETE_ACCOUNT_CANCEL: 'DELETE_ACCOUNT_CANCEL',
  DELETE_ACCOUNT_CONFIRM: 'DELETE_ACCOUNT_CONFIRM',

  SIGNUP_INITIAL: 'SIGNUP_INITIAL',
  SIGNUP_VERIFICATION: 'SIGNUP_VERIFICATION',
  SIGNUP_GOOGLE_OAUTH: 'SIGNUP_GOOGLE_OAUTH',

  DISCORD_ADD_WEBHOOK: 'DISCORD_ADD_WEBHOOK',
  DISCORD_DELETE_WEBHOOK: 'DISCORD_DELETE_WEBHOOK',

  SEND_REVIEW: 'SEND_REVIEW',

  EDIT_SITE_JUST_CLICK: 'EDIT_SITE_JUST_CLICK',
  EDIT_SITE_CONFIRM: 'EDIT_SITE_CONFIRM',

  DELETE_SITE: 'DELETE_SITE',

  RESET_PASSWORD: 'RESET_PASSWORD',

  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_USE: 'TERMS_OF_USE',

  SEND_CHANGE_FEEDBACK: 'SEND_CHANGE_FEEDBACK',

  ADD_PACKAGE: 'ADD_PACKAGE',
};

export const registerEvent = (eventType: string): void => {
  const userEmail = getEmailFromToken();
  if (!userEmail) {
    window.plausible(eventType);
  }
  window.plausible(eventType, {
    props: {
      email: userEmail,
    },
  });
};
